<script>
import { msalInstance } from "@/authConfig";
import { onMounted } from "vue";
export default {
  setup() {
    const idTokenHint = localStorage.getItem("tokenAdb2c");
    onMounted(async () => {
      msalInstance.logoutRedirect({
        idTokenHint: idTokenHint,
      });
    });
  },
};
</script>
